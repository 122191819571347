import React, { useState, useEffect, useContext } from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../layout/main";
import styled from "styled-components";
import SimpleAccordion from "../components/Accordion/old";
import { SidebarObject } from "../components/Sidebar";
import Header from "../components/Header";
import Accordion from "../components/Accordion";
import Markdown, { compiler } from "markdown-to-jsx";
import SectionsObject from "../helpers/sections";
import specialtySubheadings from "../helpers/specialtySubheadings";
import Promotion from "../components/Promotion";
import StationCardCollection from "../containers/Specialty/StationCardCollection";
import NewButtonCard from "../components/ButtonCards/NewButtonCard";
import { FirebaseContext } from "../components/Firebase";
import NewUnavailableButtonCard from "../components/ButtonCards/NewUnavailableButtonCard";
import SEO from "../components/seo";
import { stationTypeDictionary } from "../helpers/pathDictionary";
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
} from "@mui/material";
import Usps from "../containers/Home/Usps";

const H3Wrapper = styled.div`
  margin-bottom: 0px;
`;

const Line = styled.div`
  height: 4px;
  background: black;
  margin-bottom: 15px;
`;

const ActorWrapper = styled.div`
  user-select: none;
`;

const ChecklistWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 100px;
  margin-top: 60px;
`;

const ProgressbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const ProgressbarInnerWrapper = styled.div`
  max-width: 50%;
  margin-top: 40px;
`;

const FormControlWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Examples = (props) => {
  const stationsInformation = props.data.allStrapiStations.edges;
  const [type, setType] = useState("");

  const { user, firebase, loading } = useContext(FirebaseContext);
  const [performanceData, setPerformanceData] = useState({});
  const [maxStationPercentages, setMaxStationPercentages] = useState({});
  useEffect(() => {
    if (!loading) {
      if (user) {
        firebase
          .retrieveStationsData()
          .then((response) => setPerformanceData(response));
      }
    }
  }, [loading]);
  useEffect(() => {
    if (!!performanceData.data) {
      const reducedArray = Object.values(
        performanceData.data.reduce((r, o) => {
          r[o.stationId] =
            r[o.stationId] && r[o.stationId].percentage > o.percentage
              ? r[o.stationId]
              : o;

          return r;
        }, {})
      );
      setMaxStationPercentages(reducedArray);
    }
  }, [performanceData]);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  return (
    <>
      <SEO
        title={`Example OSCE Stations`}
        description={`OSCE practice cases for medical students. Scenarios include history-taking, examination, A-E assessment, data interpretation and more! `}
      />
      <Header
        title="Example Stations 🩺"
        subheading="FREE sample of OSCE stations. Access more with a 7-day free trial."
        darkMode={false}
      />
      <StationCardCollection>
        {stationsInformation.map((station) => {
          const node = station.node;
          const available = node.coming_soon == false;
          var percentage = 0;
          if (maxStationPercentages.length > 0) {
            const thisStation = maxStationPercentages.filter(
              (station) => station.stationId == node.strapiId
            )[0];
            if (thisStation) {
              percentage = thisStation.percentage;
            }
          }
          if (node.example) {
            return (
              <>
                {available ? (
                  <NewButtonCard
                    stationTitle={node.Title}
                    stationType={stationTypeDictionary[node.type]}
                    stationId={node.strapiId}
                    percentage={percentage}
                    subscriberOnly={node.subscriber_only}
                    recommendedTime={node.Recommended_time}
                  />
                ) : (
                  <NewUnavailableButtonCard
                    stationTitle={node.Title}
                    stationType={stationTypeDictionary[node.type]}
                    stationId={node.strapiId}
                    subscriberOnly={node.subscriber_only}
                  />
                )}
              </>
            );
          }
        })}
      </StationCardCollection>
      <Usps />
      {!user && <Promotion />}
    </>
  );
};

export default Examples;

export const query = graphql`
  query Examples {
    allStrapiStations(
      sort: { fields: [coming_soon, Title], order: [ASC, ASC] }
    ) {
      edges {
        node {
          strapiId
          Title
          Specialty
          subscriber_only
          coming_soon
          cover_image {
            url
          }
          type
          Recommended_time
          example
        }
      }
    }
  }
`;
