const specialtySubheadings = {
  cardiorespiratory:
    "Cardiology and respiratory OSCE stations for medical students",
  gastro: "Gastroenterology (GI) OSCE stations for medical students",
  neuro: "Neurology OSCE stations for medical students",
  haem: "Haematology OSCE stations for medical students",
  id: "Infectious disease OSCE stations for medical students",
  renal: "Renal",
  rheum: "Rheumatology OSCE stations for medical students",
  endo: "Endocrinology OSCE stations for medical students",
  derm: "Dermatology OSCE stations for medical students",
  ent: "ENT OSCE stations for medical students",
  gp: "GP OSCE stations for medical students",
  gynae: "Gynaecology OSCE stations for medical students",
  obstetrics: "Obstetrics OSCE stations for medical students",
  ophthal: "Ophthalmology (eye) OSCE stations for medical students",
  paeds: "Paediatrics OSCE stations for medical students",
  psych: "Psychiatric OSCE stations for medical students",
  breast: "Breast OSCE stations for medical students",
  generalsurg: "General surgery OSCE stations for medical students",
  traumaortho: "Trauma and orthopaedics OSCE stations for medical students",
  urology: "Urology OSCE stations for medical students",
  vascular: "Vascular surgery OSCE stations for medical students",
  miscellaneous: "OSCE stations that don’t fit any of the other categories!",
  ethics_law: "Ethics and law OSCE stations for medical students",
};

export default specialtySubheadings;
