import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const ImageBackground = styled.img`
  position: absolute;
  z-index: 0;
  height: 60px;
  width: auto;
`;

const SubscriberOnlyIcon = styled.span`
  z-index: 10;
  position: absolute;
  color: #ecebff;
  font-weight: 400;
  font-size: 18px;
  padding: 8px 10px;
  top: 0px;
  left: 0px;
`;

function NewButtonCard({
  stationType,
  stationTitle,
  stationImageURL,
  comingSoon,
  percentage,
  subscriberOnly,
  recommendedTime,
}) {
  console.log(recommendedTime);
  const [cardClass, setCardClass] = useState("");
  useEffect(() => {
    // console.log(percentage);
    if (percentage != 0 && percentage < 50) {
      setCardClass("red");
    } else if (percentage != 0 && percentage < 75) {
      setCardClass("orange");
    } else if (percentage != 0 && percentage >= 75) {
      setCardClass("green");
    }
  }, [percentage]);
  return (
    <Link
      class={`osce-station-card ${cardClass}`}
      to={`/${stationTitle}-osce-station`}
    >
      {/* <ImageBackground src={stationImageURL}/> */}
      {subscriberOnly && <SubscriberOnlyIcon>👑</SubscriberOnlyIcon>}
      <div class="osce-station-label-div">
        <div class="tag">{stationType}</div>
        {recommendedTime && <div class="tag">{`${recommendedTime} mins`}</div>}
        {/* <div class="tag">EASY</div> */}
      </div>
      <h2 class="osce-station-title">{stationTitle}</h2>
    </Link>
  );
}

export default NewButtonCard;
