import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const ImageBackground = styled.img`
  position: absolute;
  z-index: 0;
  height: 60px;
  width: auto;
`;

const SubscriberOnlyIcon = styled.span`
  z-index: 10;
  position: absolute;
  color: #ecebff;
  font-weight: 400;
  font-size: 18px;
  padding: 8px 10px;
  top: 0px;
  left: 0px;
`;

const ComingSoonSpan = styled.span`
  z-index: 10;
  position: absolute;
  color: #ecebff;
  font-weight: 400;
  font-size: 18px;
  background-color: #1e173f;
  padding: 8px 10px;
  border-radius: 8px;
  top: 20px;
  right: 0px;
  transform: rotate(15deg);
`;

function NewUnavailableButtonCard({
  stationType,
  stationTitle,
  stationImageURL,
  comingSoon,
  subscriberOnly,
}) {
  return (
    <div class="osce-station-card-unavailable">
      <ComingSoonSpan>COMING SOON</ComingSoonSpan>
      {subscriberOnly && <SubscriberOnlyIcon>👑</SubscriberOnlyIcon>}

      {/* <ImageBackground src={stationImageURL}/> */}
      <div class="osce-station-label-div">
        <div class="tag">{stationType}</div>
        {/* <div class="tag">EASY</div> */}
      </div>
      <h2 class="osce-station-title">{stationTitle}</h2>
    </div>
  );
}

export default NewUnavailableButtonCard;
