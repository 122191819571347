import React from "react";
import NewButtonCard from "../../components/ButtonCards/NewButtonCard";

function StationCardCollection({children}) {
  return (
    <div class="section-browse wf-section">
      <div class="browse-container w-container">
        <div class="w-layout-grid grid">
          {children}
        </div>
      </div>
    </div>
  );
}

export default StationCardCollection;
